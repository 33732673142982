






































import { Vue, Component, Ref } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import RegisterLayout from '@/components/RegisterLayout.vue';
import UserStore from '@/store/modules/user';
import NotificationService from '@/components/Notification.service';

@Component({
  name: 'RegisterView',
  components: {
    RegisterLayout,
  },
})
export default class RegisterView extends Vue {
  @Ref('registerForm') readonly registerForm!: HTMLFormElement;

  userStore = getModule(UserStore, this.$store);

  email = '';

  password = '';

  password2 = '';

  get rules() {
    return {
      email: [(v: string) => !!v.length || 'Email es requerido'],
      pass1: [
        (v: string) => v.length >= 8 || 'Contraseña tiene que tener mas de 8 caracteres',
        (v: string) => !!v.length || 'Contraseña es requerida',
      ],
      pass2: [
        (v: string) => !!v.length || 'Contraseña es requerida',
        (v: string) => v === this.password || 'Contraseña no coincide',
      ],
    };
  }

  async register() {
    const isValid: boolean = this.registerForm.validate();
    if (!isValid) {
      return;
    }
    const response = await this.userStore.register({
      username: this.email,
      password: this.password,
    });
    if (response.email instanceof Array) {
      NotificationService.show({
        text: `Email: ${response.email.toString()}`,
        color: 'error',
      });
      return;
    }
    this.$router.push({ name: 'app-home' });
  }
}
